import React, { useState } from "react";
import { Tabs } from "antd";
import {
  RocketOutlined,
  TeamOutlined,
  MinusOutlined,
  ShopOutlined,
} from "@ant-design/icons";

import Exteriores from "src/components/membrana/why/Exteriores";
import Interiores from "src/components/membrana/why/Interiores";
import Cubiertas from "src/components/membrana/why/Cubiertas";
import Industrial from "src/components/membrana/why/Industrial";

const { TabPane } = Tabs;
export const MembranaWhyComponent = (props) => {
  const [activeContent, setActiveContent] = useState("thermoProtect");

  //  const callback = (key) => console.log(key);

  console.log("el activeCOntent", activeContent);

  return (
    <>
      <div className={`membranaContentTabs ${activeContent}`}>
        <Tabs
          defaultActiveKey="thermoProtect"
          centered={true}
          onChange={setActiveContent}
        >
          <TabPane
            className="thermoProtect"
            tab={
              <span className="standard colored">
                <ShopOutlined />
                Para Exteriores
              </span>
            }
            key="thermoProtect"
          >
            <Exteriores />
          </TabPane>
          <TabPane
            className="thermoPlus"
            tab={
              <span className="standard colored">
                <TeamOutlined />
                Para Interiores
              </span>
            }
            key="thermoPlus"
          >
            <Interiores />
          </TabPane>
          <TabPane
            className="thermoActive"
            tab={
              <span className="standard colored">
                <MinusOutlined />
                Para cubiertas/tejados
              </span>
            }
            key="thermoActive"
          >
            <Cubiertas />
          </TabPane>
          <TabPane
            className="industrySpecial"
            tab={
              <span className="standard colored">
                <RocketOutlined />
                Industrial
              </span>
            }
            key="inustrySpecial"
          >
            <Industrial />
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};

export default MembranaWhyComponent;
