import React from "react";

import ferroviaria from "src/components/membrana/why/images/industrial/climateCoating-industria-ferroviaria.png";
import maritimo from "src/components/membrana/why/images/industrial/climateCoating-industrial-contenedores-maritimos.png";
import tanquesAlmacenamiento from "src/components/membrana/why/images/industrial/climateCoating-industrial-tanques-de-almacenamiento.png";
import toldosCarpas from "src/components/membrana/why/images/industrial/climateCoating-industrial-toldos-carpas.png";

import "src/components/membrana/how/style.scss";

const WhyIndustrial = (props) => {
  return (
    <div className="standardContent contentBlock marginAuto">
      <div className="blockImgLeft">
        <div className="blockImg">
          <img className="applicationsLeftImg" src={tanquesAlmacenamiento} />
          <span className="legend">
            ClimateCoating® IndustrySpecial es muy provechoso para revestir
            tanques de almacenamiento
          </span>
        </div>
        <span className="blockText marginTop10">
          <h2>
            <strong>Tanques y depósitos: </strong> Industria alimentaria,
            química...{" "}
          </h2>
          <p>
            Al evitar las constantes fluctuaciones de la temperatura, se evita
            la habitual sobrepresión de la capacidad del tanque y las pérdidas
            de material que está genera.
          </p>
        </span>
      </div>
      <div className="clearBoth" />
      <div className="blockImgLeft">
        <div className="blockImg">
          <img className="applicationsLeftImg" src={maritimo} />
          <span className="legend">
            La resistencia y durabilidad de la membrana de ClimateCoating® puede
            ser de mucha utilidad para contenedores marítimos
          </span>
        </div>
        <span className="blockText ">
          <h2>
            <strong>Transporte: </strong>Contenedores marítimos
          </h2>
          <p>
            Durante los largos desplazamientos por el océano y sometidos a altas
            temperaturas, permite que la mercancía viaje sin deteriorarse{" "}
          </p>{" "}
        </span>
      </div>
      <div className="clearBoth" />
      <div className="blockImgLeft">
        <div className="blockImg">
          <img className="applicationsLeftImg marginTop10" src={ferroviaria} />
          <span className="legend">
            ClimateCoating® para la Industria Ferroviaria
          </span>
        </div>

        <span className="blockText">
          <h2>
            <strong>Industria Ferroviaria: </strong>Vagones
          </h2>
          <p>
            Los vagones de los trenes están altas temperaturas, especialmente en
            verano. Sometidos a altas temperaturas, Con IndustrySpecial, las
            temperaturas interiores especialmente en verano bajan, y se
            contribuye a un mayor confort interior y a un funcionamiento
            mecánico fiable.
          </p>
        </span>
      </div>
      <div className="clearBoth" />

      <div className="blockImgLeft">
        <div className="blockImg">
          <img className="applicationsLeftImg marginTop10" src={toldosCarpas} />
          <span className="legend">
            La membrana termocerámica de ClimateCoating® permite revestir toldos
            y carpas y aprovechar así sus ventajas endotérmicas
          </span>
        </div>

        <span className="blockText">
          <h2>
            <strong>Tejidos y Materiales Elásticos: </strong> Carpas, Toldos e
            instalaciones provisionales
          </h2>
          <p>
            Para poder soportar fluctuaciones de temperatura en todas las
            regiones geográficas, el material textil se reviste con
            IndustrySpecial. Así, se alcanzan temperaturas agradables dentro de
            carpas y tiendas de campaña tanto en verano como en invierno.
          </p>
        </span>
      </div>
      <div className="clearBoth" />
    </div>
  );
};

export default WhyIndustrial;
