import React from "react";

import colorStability from "src/components/membrana/why/images/exteriores/climateCoating-exteriores-alta-estabilidad-al-color.png";
import resistenceExternal from "src/components/membrana/why/images/exteriores/climateCoating-exteriores-alta-resistencia-agentes-externos.png";
import applicationMultipleSurfaces from "src/components/membrana/why/images/exteriores/climateCoating-exteriores-aplicacion-multiples-superficies.png";
import variablePermeability from "src/components/membrana/why/images/exteriores/climateCoating-exteriores-permeabilidad-variable.png";
import durableProtection from "src/components/membrana/why/images/exteriores/climateCoating-exteriores-proteccion-duradera.png";
import reflectivityEmissivity from "src/components/membrana/why/images/exteriores/climateCoating-exteriores-reflectividad-emisividad-sample.jpg";

import "src/components/membrana/how/style.scss";

const WhyExteriores = (props) => {
  return (
    <div className="standardContent">
      <div className="contentBlock marginAuto">
        <div className="blockImgLeft">
          <div className="blockImg">
            <img className="applicationsLeftImg" src={variablePermeability} />
            <span className="legend">
              ClimateCoating® es impermeable al Agua y permeable al Vapor de
              Agua
            </span>
          </div>
          <span className="blockText marginTop10">
            <h2>
              <strong>Gestión del agua/humedad:</strong> Permeabilidad Variable
            </h2>
            <p>
              Por un lado, la resina polimérica al entrar en contacto con el
              agua (lluvia, granizo, nieve, etc) incrementa su volumen en torno
              a un 25% volviéndose impermeable y protegiendo la fachada. Por
              otro lado, cuando las condiciones ambientales son secas, la
              membrana, que es higroscópica, extrae la humedad de la envolvente
              y la expulsa al exterior en forma de vapor de agua.
            </p>
          </span>
        </div>
        <div className="clearBoth" />
        <div className="blockImgLeft">
          <div className="blockImg">
            <img className="applicationsLeftImg" src={reflectivityEmissivity} />
            <span className="legend">
              ClimateCoating® tiene una alta Reflectancia y Emisividad
            </span>
          </div>
          <span className="blockText marginTop0">
            <h2>
              <strong>Calor del Sol:</strong> Alta reflectancia y emisividad
            </h2>
            <p>
              La membrana de ClimateCoating® es capaz de reflectar y de emitir
              la radiación solar que recibe, de modo que del calor recibido, se
              transmite al interior un pequeño porcentaje, reduciendo el consumo
              energético necesario para la refrigeración de los espacios.
            </p>{" "}
          </span>
        </div>
        <div className="clearBoth" />
        <div className="blockImgLeft">
          <div className="blockImg">
            <img
              className="applicationsLeftImg marginTop10"
              src={resistenceExternal}
            />
            <span className="legend">
              ClimateCoating® ofrece una protección duradera a las edificaciones
            </span>
          </div>

          <span className="blockText marginTop0">
            <h2>
              <strong>Erosión Externa:</strong> Alta resistencia
            </h2>
            <p>
              {" "}
              ClimateCoating®, gracias a su resistencia y elasticidad, evita
              fisuras en las fachadas y las protege del deterioro que producen
              la contaminación del aire, el salitre (en zonas cercanas al mar) y
              la radiación UV.
            </p>
          </span>
        </div>
        <div className="clearBoth" />

        <div className="blockImgLeft">
          <div className="blockImg">
            <img
              className="applicationsLeftImg marginTop10"
              src={durableProtection}
            />
            <span className="legend">
              ClimateCoating® ofrece una protección duradera a las edificaciones
            </span>
          </div>

          <span className="blockText marginTop0">
            <h2>
              <strong>Paredes:</strong> Protección dudadera
            </h2>
            <p>
              {" "}
              Mantiene el interior de los muros en condiciones de humedad bajas,
              evitando que aparezcan condensaciones y mohos. Al no producirse
              fisuras en el revestimiento, no se produce entrada de agua, de
              modo que la fachada está protegida.
            </p>
          </span>
        </div>
        <div className="clearBoth" />

        <div className="blockImgLeft">
          <div className="blockImg">
            <img
              className="applicationsLeftImg marginTop10"
              src={colorStability}
            />
            <span className="legend">
              ClimateCoating® logra un alta estabilidad de los colores
            </span>
          </div>

          <span className="blockText marginTop0">
            <h2>
              <strong>Estética: </strong> Mantiene la intensidad de los colores
            </h2>
            <p>
              {" "}
              La durabilidad de la intensidad del color de ClimateCoating® es
              muy superior a la de las pinturas, alargando en un 100% los plazos
              de renovación por motivos estéticos, lo cual supone un ahorro en
              coste de mantenimiento. Cuando , pasados 10 años desde la
              aplicación de una pintura en una fachada, ésta se ve deteriorada y
              con signos de decoloración, ClimateCoating® se ve en un estado muy
              similar al de su aplicación inicial
            </p>
          </span>
        </div>
        <div className="clearBoth" />

        <div className="blockImgLeft">
          <div className="blockImg">
            <img
              className="applicationsLeftImg marginTop10"
              src={applicationMultipleSurfaces}
            />
            <span className="legend">
              ClimateCoating® se puede aplicar en multitud de materiales
            </span>
          </div>

          <span className="blockText marginTop0">
            <h2>
              <strong>Adherencia: </strong>Aplicación en distintas superficies
            </h2>
            <p>
              {" "}
              Puede ser aplicado en distintas superficies. Además, hay un
              producto específico para madera exterior (Nature) que, tanto por
              su resistencia a los rayos UV, como por su elasticidad ante la
              expansión y contracción de la madera, ralentiza el proceso de
              envejecimiento de la misma de manera notable.
            </p>
          </span>
        </div>
        <div className="clearBoth" />
      </div>
      <div className="standardContentBottomTitle">
        ClimateCoating® es impermeable al Agua y permeable al Vapor de Agua
      </div>
    </div>
  );
};

export default WhyExteriores;
