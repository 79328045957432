import React from "react";

import { Content } from "antd/lib/layout/layout";

import { LayoutGeneral } from "src/layout/LayoutGeneral";
import MembranaWhyComponent from "src/components/membrana/why/Component";

const PorQueElegirla = (props) => (
  <LayoutGeneral
    {...{
      ...props,
      pageTitleJSX: (
        <span>
          <span className="mainTitle"> Eficiencia, Confort y Protección: </span>
          <span className="secondaryTitle">
            la Membrana Termocerámica de ClimateCoating tiene propiedades únicas
          </span>
        </span>
      ),
      pageTitle:
        "Protección frente a la erosion exterior y confort en el interior",
      pageName: "Membrana de ClimateCoating: ¿Por qué elegirla?",
      pageSubTitle:
        "La Membrana de ClimateCoating: El exterior del edificio sufre la radiación solar y la erosión del medio, las paredes interiores están en contacto las personas que lo  habitan",
      pageDescription:
        "La Membrana de ClimateCoating: El exterior del edificio sufre la radiación solar y la erosión del medio, las paredes interiores están en contacto las personas que lo  habitan",
    }}
  >
    <Content className="page">
      <MembranaWhyComponent />
    </Content>
  </LayoutGeneral>
);

export default PorQueElegirla;
