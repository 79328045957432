import React from "react";

import equlibrioTermico from "src/components/membrana/why/images/interiores/climateCoating-equilibrio-termico-interiores.png";
import alergicosAsmaticos from "src/components/membrana/why/images/interiores/climateCoating-ideal-alergicos-asmaticos.png";
import confortInteriores from "src/components/membrana/why/images/interiores/climateCoating-interiores-confort-interiores.png";
import controlHumedad from "src/components/membrana/why/images/interiores/climateCoating-interiores-control-de-la-humedad-interior.png";
import moho from "src/components/membrana/why/images/interiores/climateCoating-previene-aparicion-moho.png";

import "src/components/membrana/how/style.scss";

const WhyInteriores = (props) => {
  return (
    <div className="standardContent contentBlock marginAuto">
      <div className="blockImgLeft">
        <div className="blockImg">
          <img className="applicationsLeftImg" src={controlHumedad} />
          <span className="legend">
            ClimateCoating® favorece que la humedad relativa en el interior
            oscile alrededor del 55%
          </span>
        </div>
        <span className="blockText ">
          <h2>
            <strong>Permeabilidad: </strong> Control de la humedad interior
          </h2>
          <p>
            Gracias a su capacidad de difusión del vapor, regula la humedad
            relativa de los espacios interiores en el entorno del 55%, que es el
            valor que proporciona las condiciones de confort.
          </p>
        </span>
      </div>
      <div className="clearBoth" />
      <div className="blockImgLeft">
        <div className="blockImg">
          <img className="applicationsLeftImg" src={confortInteriores} />
          <span className="legend">
            ClimateCoating® proporciona un elevado confort para las estancias
            interiores
          </span>
        </div>
        <span className="blockText ">
          <h2>
            <strong>Muros Secos: </strong> Confort térmico en cualquier estación
            del año
          </h2>
          <p>
            En verano, absorbe la humedad de la envolvente y la expulsa hacia la
            superficie de la pared, de manera similar a como en verano se enfría
            la piel mediante la sudoración, provocando un frescor agradable
            incluso sin aire acondicionado.
          </p>{" "}
        </span>
      </div>
      <div className="clearBoth" />
      <div className="blockImgLeft">
        <div className="blockImg">
          <img className="applicationsLeftImg " src={equlibrioTermico} />
          <span className="legend">
            Con ClimateCoating® se reduce de manera significativa el efecto
            «pies fríos/cabeza caliente
          </span>
        </div>

        <span className="blockText">
          <h2>
            <strong>Equilibrio térmico: </strong>
            Sin efecto pies fríos/cabeza caliente
          </h2>
          <p>
            En ClimateCoating® ThermoPlus la membrana Termocerámica se ocupa de
            lograr una distribución homogénea del calor en la estancia mediante
            un efecto endotérmico. La diferencia de temperatura entre el suelo y
            la zona del techo puede así suponer menos de 2°C, en lugar de unos
            10 °C, que es lo habitual en estancias con sistemas de calefacción
            por radiadores o aire acondicionado. De esta forma, evitamos el
            efecto » pies fríos/cabeza caliente», tan dañino para el bienestar
            de las personas.
          </p>
        </span>
      </div>
      <div className="clearBoth" />

      <div className="blockImgLeft">
        <div className="blockImg">
          <img className="applicationsLeftImg " src={moho} />
          <span className="legend">
            ClimateCoating® ThermoPlus previene la aparición de moho en las
            estancias interiores
          </span>
        </div>

        <span className="blockText">
          <h2>
            <strong>Antihumedades: </strong>
            Evita la formación de mohos
          </h2>
          <p>
            Con el equilibrio térmico se evita la formación de condensación en
            las paredes y la formación de mohos, incluso en estancias con escasa
            ventilación como garajes.
          </p>
        </span>
      </div>
      <div className="clearBoth" />

      <div className="blockImgLeft">
        <div className="blockImg">
          <img className="applicationsLeftImg " src={alergicosAsmaticos} />
          <span className="legend">
            Las propiedades de ClimateCoating® ThermoPlus hacen que sea un
            producto ideal para alérgicos y asmáticos
          </span>
        </div>

        <span className="blockText">
          <h2>
            <strong>Ecológico: </strong>Recomendado para alérgicos y asmáticos
          </h2>
          <p>
            Se trata de un producto en base agua, sin disolventes ni productos
            químicos agresivos. No libera ninguna No libera ninguna sustancia
            química reconocida sustancia química reconocida como alergénica.
          </p>
        </span>
      </div>
      <div className="clearBoth" />
    </div>
  );
};

export default WhyInteriores;
