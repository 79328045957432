import React from "react";

import proteccionDuradera from "src/components/membrana/why/images/cubiertas/cliamteCoating-cubiertas-proteccion-duradera.png";
import eficienciaEnergetica from "src/components/membrana/why/images/cubiertas/climateCoating-cubiertas-eficiencia-energetica.png";
import elastico from "src/components/membrana/why/images/cubiertas/climateCoating-cubiertas-elastico.png";
import impermeable from "src/components/membrana/why/images/cubiertas/climateCoating-cubiertas-impermeable.png";
import todasSuperficies from "src/components/membrana/why/images/cubiertas/climateCoating-cubiertas-todas-las-superficies.png";

import "src/components/membrana/how/style.scss";

const WhyCubiertas = (props) => {
  return (
    <div className="standardContent contentBlock marginAuto">
      <div className="blockImgLeft">
        <div className="blockImg">
          <img className="applicationsLeftImg" src={eficienciaEnergetica} />
          <span className="legend">
            La reacción endotérmica que genera la membrana de ClimateCoating®
            logra un importante ahorro en el consumo energético
          </span>
        </div>
        <span className="blockText marginTop10">
          <h2>
            <strong>Ahorro: </strong>Eficiencia Energética
          </h2>
          <p>
            Al igual que el resto de los productos ClimateCoating®, garantiza
            una importante eficiencia energética tanto en invierno como en
            verano, ahorrando energía y su correspondiente coste económico.
          </p>
        </span>
      </div>
      <div className="clearBoth" />
      <div className="blockImgLeft">
        <div className="blockImg">
          <img className="applicationsLeftImg" src={proteccionDuradera} />
          <span className="legend">
            Gracias a su estabilidad, flexibilidad e impermeabilidad confiere
            una protección duradera y fiable.
          </span>
        </div>
        <span className="blockText ">
          <h2>
            <strong>Protección: </strong>Máxima protección frente a la erosión
          </h2>
          <p>
            Protección duradera de la cubierta frente a agentes medioambientales
            nocivos como la radiación ultravioleta, combinación del aire,
            suciedad, ácido y salitre, así como las condiciones climatológicas
            adversas.
          </p>{" "}
        </span>
      </div>
      <div className="clearBoth" />
      <div className="blockImgLeft">
        <div className="blockImg">
          <img
            className="applicationsLeftImg marginTop10"
            src={todasSuperficies}
          />
          <span className="legend">
            ClimateCoating® es impermeable al agua evitando su paso al elemento
            constructivo que recubre.
          </span>
        </div>

        <span className="blockText">
          <h2>
            <strong>Adherencia: </strong> Aplicable a todo tipo de superficies
          </h2>
          <p>
            Se puede aplicar en todo tipo de superficies gracias a su excelente
            adherencia, incluyendo chapas metálicas.
          </p>
        </span>
      </div>
      <div className="clearBoth" />

      <div className="blockImgLeft">
        <div className="blockImg">
          <img className="applicationsLeftImg marginTop10" src={impermeable} />
          <span className="legend">
            ClimateCoating® es impermeable al agua evitando su paso al elemento
            constructivo que recubre.
          </span>
        </div>

        <span className="blockText">
          <h2>
            <strong>Impermeable</strong>: Incluso en condiciones extremas
          </h2>
          <p>
            ClimateCoating® es impermeable al agua incluso bajo las condiciones
            climatológicas más extremas. Tiene asimismo un efecto de
            amortiguación del ruido provocado por la lluvia o el granizo.
          </p>
        </span>
      </div>
      <div className="clearBoth" />

      <div className="blockImgLeft">
        <div className="blockImg">
          <img className="applicationsLeftImg marginTop10" src={elastico} />
          <span className="legend">
            La membrana termocerámica de ClimateCoating® es elástica y se adapta
            a la forma de la superficie en la que se aplica.
          </span>
        </div>

        <span className="blockText">
          <h2>
            <strong>Elasticidad: </strong> Elevada{" "}
          </h2>
          <p>
            Dada su elasticidad se reduce la aparición de fisuras. Permanece
            elástico en los rangos de temperatura entre -40°C hasta +150°C.
            Extremadamente flexible en juntas.
          </p>
        </span>
      </div>
      <div className="clearBoth" />
    </div>
  );
};

export default WhyCubiertas;
